var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Registration Details ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"5","lg":"5","md":"5","xs":"5"}},[_c('v-card-subtitle',[_c('b',[_vm._v("Realm :")])]),_c('v-card-subtitle',[_c('b',[_vm._v("Account Name :")])]),_c('v-card-subtitle',[_c('b',[_vm._v("User :")])])],1),_c('v-col',{attrs:{"cols":"7","lg":"7","md":"7","xs":"7"}},[_c('v-card-subtitle',[_vm._v(_vm._s(_vm.registrationDetail.account_realm))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.registrationDetail.account_name))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.registrationDetail.from_user))])],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"basil","grow":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.items),function(tab,box){return _c('v-tab',{key:box,on:{"change":function($event){return _vm.tabClickHandler(box)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}}),_c('v-card',{attrs:{"color":"basil","flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.liveCallHeaders,"items":_vm.liveCallList},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.liveCallList.indexOf(item) + 1)+" ")]}},{key:"item.statusId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeText( item.statusId == true ? "Connected" : "Connecting" ))+" ")]}}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}}),_c('v-card',{attrs:{"color":"basil","flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.currentHeaders,"items":_vm.currentList,"server-items-length":_vm.totalRegistrationItem,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.options.page - 1) * _vm.options.itemsPerPage + (_vm.currentList.indexOf(item) + 1)))]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderId)+" ")]}},{key:"item.orderType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderType)+" ")]}},{key:"item.statusId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeText(item.statusId))+" ")]}},{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.orderDate).format("MMMM Do YYYY, h:mm:ss a"))+" ")]}},{key:"item.grandTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.grandTotal)+" ")]}},{key:"item.viewAction",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog(item)}}},[_vm._v("visibility")])]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }